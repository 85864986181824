import React from "react";

const CrossIcon = (props) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={12}
                height={12}
                fill="none"
                {...props}
            >
                <g className=" stroke-current" strokeWidth={2} opacity={0.75}>
                    <path d="m.976 10.692 9.384-9.384M.974 1.308l9.384 9.384" />
                </g>
            </svg>
        </>
    );
};

export default CrossIcon;
