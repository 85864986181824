import React from "react";

export default function RadioFilled({ selected, item }) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.75"
                y="0.75"
                width="16.5"
                height="16.5"
                rx="8.25"
                stroke={selected === item ? "white" : "#ffffff80"}
                strokeWidth="1.5"
            />
            {selected === item && <circle cx="9" cy="9" r="4" fill="white" />}
        </svg>
    );
}