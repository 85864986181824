export default function ChevronRight(props) {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                {...props}
            >
                <path
                    opacity="0.4"
                    d="M0.929688 11.0703L6 6L0.929688 0.929687"
                    stroke="white"
                    strokeWidth="2"
                />
            </svg>
        </>
    );
}
