import Link from "next/link.js";
import { usePathname } from "next/navigation";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sentDataLayerEvent } from "../../../../lib/dataLayer.js";
import { setPlanName } from "../../../../redux/slices/choosePlanSlice.js";
import CheckIcon from "../../icon/CheckIcon";
import CrossIcon from "../../icon/CrossIcon.jsx";
import RadioFilled from "../../icon/RadioFilled";
import { buttonList, initialFeatures, initialPlans } from "./data.js";

export default function PlanPricingTable({ styles, fromRoot }) {
    const router = useRouter();
    const pathname = usePathname();
    const pathArray = pathname?.split("/");

    const [selectedButton, setSelectedButton] = useState(1);
    const [selectedSubButton, setSelectedSubButton] = useState(11);
    const [plans, setPlans] = useState(initialPlans);
    const [features, setFeatures] = useState(initialFeatures);
    const dispatch = useDispatch();
    const choosePlan = useSelector((state) => state.choosePlan);
    // const activePath = choosePlan?.planName;

    const handleClick = (id, newPlans, newFeatures, subButtons) => {
        setSelectedButton(id);
        if (subButtons && subButtons.length > 0) {
            const firstSubButton = subButtons[0];
            handleSubClick(
                firstSubButton.id,
                firstSubButton.newPlans,
                firstSubButton.newFeatures
            );
        } else {
            setSelectedSubButton(null);
            setPlans(newPlans);
            setFeatures(newFeatures);
        }
    };

    const handleSubClick = (id, newPlans, newFeatures) => {
        setSelectedSubButton(id);
        setPlans(newPlans);
        setFeatures(newFeatures);
    };

    const handleGetPlans = (e) => {
        e.preventDefault();
        sentDataLayerEvent({
            event: "selected_plan",
            name: e.target.getAttribute("data-plan"),
            value: e.target.getAttribute("data-price")
        });
        window.location.href = e.target.getAttribute("href");
    };

    useEffect(() => {
        const targetPath = choosePlan?.planName;
        const targetButton = buttonList.find(
            (btn) => btn.pathname === targetPath
        );
        dispatch(setPlanName(targetPath));
        if (
            ["stellar", "express", "evaluation", "stellar-lite"].includes(
                targetPath
            )
        ) {
            handleClick(
                targetButton?.id,
                targetButton?.newPlans,
                targetButton?.newFeatures,
                targetButton?.subButtons
            );
        } else {
            router.push("/404");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [choosePlan?.planName]);

    const handleRouteChange = (routeLink) => {
        dispatch(setPlanName(routeLink));
    };

    const LOG_IN_URL = process.env.LOG_IN_URL || "https://app.fundednext.com";

    return (
        <div
            className={`container mx-auto min-h-screen px-4 pb-10 text-white ${styles}`}
        >
            <div className="mx-auto mb-7 flex max-w-[500px] items-center justify-between rounded-full border border-[#ffffff1a] bg-gradient-to-b from-[#ffffff0f] to-[#ffffff14] px-2 py-2">
                {buttonList.map((button, idx) => (
                    <div key={idx} className="relative">
                        <button
                            onClick={() => handleRouteChange(button?.pathname)}
                            className={`flex items-center justify-center border px-4 py-1 text-lg font-medium transition-all duration-200 ease-in-out ${selectedButton === button.id ? "rounded-full border-white border-opacity-0 bg-white/10" : "border-transparent opacity-50"}`}
                        >
                            {button.button_name}
                        </button>
                    </div>
                ))}
            </div>
            <div
                className={`sub-buttons ${choosePlan?.planName === "stellar" || choosePlan?.planName === "express" ? "sub-buttons-open" : "sub-buttons-close"}`}
            >
                <div className="h-[84px]">
                    {buttonList.map(
                        (button) =>
                            button.subButtons &&
                            selectedButton === button.id && (
                                <div
                                    className="flex h-[84px] items-center justify-center gap-[30px] pb-[60px]"
                                    key={button.id + "fn"}
                                >
                                    <h3 className="text-base text-[#ffffff80]">
                                        Select Preference
                                    </h3>
                                    <div className="space-x-4">
                                        {button.subButtons.map((subButton) => (
                                            <button
                                                key={subButton.id}
                                                onClick={() => {
                                                    handleSubClick(
                                                        subButton.id,
                                                        subButton.newPlans,
                                                        subButton.newFeatures
                                                    );
                                                }}
                                            >
                                                <RadioFilled
                                                    selected={selectedSubButton}
                                                    item={subButton.id}
                                                />
                                                <span
                                                    className={`text-base font-medium ${selectedSubButton === subButton.id ? "text-white" : "text-[#ffffff80]"}`}
                                                >
                                                    &nbsp;{" "}
                                                    {subButton.button_name}
                                                </span>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )
                    )}
                </div>
            </div>
            <div className="mb-[10px] mt-[10px] flex items-center justify-end">
                <Link href="/package-comparison" target="_blank">
                    <button className="text-[14px] font-semibold text-white/60 underline">
                        Compare Challenges
                    </button>
                </Link>
            </div>
            <div className="mb-40 rounded-2xl bg-[#ffffff0f]">
                <div className="hide-scroll-bar overflow-x-auto">
                    <table className="min-w-full">
                        <thead>
                            <tr className="relative">
                                <th className="border-r border-[#ffffff1a] px-6 text-start text-[15px] font-semibold text-white opacity-50">
                                    <p className="absolute top-10">
                                        Account Size
                                    </p>
                                </th>
                                {plans.map((plan, index) => (
                                    <th
                                        key={index}
                                        className={`px-1 text-center text-xl font-medium ${index < plans.length - 1 ? "border-r border-[#ffffff1a]" : ""}`}
                                    >
                                        <div className="flex flex-col items-center">
                                            <span className="mt-[30px]">
                                                {plan.amount}
                                            </span>
                                            <a
                                                data-price={plan.fee.replace(
                                                    "$",
                                                    ""
                                                )}
                                                className="buyButton"
                                                data-plan={
                                                    plan.name +
                                                    " " +
                                                    plan.amount
                                                }
                                                id={`buy${plan.planId}`}
                                                href={`${LOG_IN_URL}/subscribe/${plan.planId}`}
                                                onClick={handleGetPlans}
                                            >
                                                <span className="pointer-events-none mt-4 flex flex-col items-center rounded-[10px] bg-[#635BFF] px-6 py-2">
                                                    <span className="text-lg font-semibold text-white">
                                                        Get Plan
                                                    </span>
                                                    <span className="text-xs font-medium text-white opacity-75">
                                                        Fee: {plan.fee}
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>

                        <tbody>
                            {features.map((feature, featureIndex) => (
                                <tr
                                    key={featureIndex}
                                    className="table-row hover:bg-[#ffffff05]"
                                >
                                    <td className="border-r border-[#ffffff1a] px-6 py-4">
                                        <div className="w-[236px] text-[15px] font-normal text-white">
                                            {feature.name}
                                        </div>
                                    </td>
                                    {plans.map((_, planIndex) => (
                                        <td
                                            key={planIndex}
                                            className={`px-12 py-4 text-center xl:px-6 ${planIndex < plans.length - 1 ? "border-r border-dashed border-[#ffffff1a]" : ""}`}
                                        >
                                            {feature.availability[planIndex] ===
                                            true ? (
                                                <CheckIcon className="h-[14px] w-[14px]" />
                                            ) : feature.availability[
                                                  planIndex
                                              ] === false ? (
                                                <CrossIcon className="h-[14px] w-[14px] opacity-40" />
                                            ) : (
                                                // <p className="text-[14px] font-normal">
                                                //     {
                                                //         feature.availability[
                                                //             planIndex
                                                //         ].split("|")[0]
                                                //     }
                                                //     <br />
                                                //     {
                                                //         feature.availability[
                                                //             planIndex
                                                //         ].split("|")[1]
                                                //     }
                                                // </p>

                                                <p className="text-[14px] font-normal">
                                                    {/* {typeof feature
                                                        .availability[
                                                        planIndex
                                                    ] === "string" ? (
                                                        feature.availability[
                                                            planIndex
                                                        ]
                                                    ) : (
                                                        <>
                                                            {feature.availability[
                                                                planIndex
                                                            ].map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            "feature-availabilty-" +
                                                                            index
                                                                        }
                                                                    >
                                                                        {item}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )} */}

                                                    {
                                                        feature.availability[
                                                            planIndex
                                                        ]
                                                    }
                                                </p>
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
