import { usePathname } from "next/navigation";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sentDataLayerEvent } from "../../../../lib/dataLayer.js";
import { setPlanName } from "../../../../redux/slices/choosePlanSlice.js";
import CheckIcon from "../../icon/CheckIcon";
import ChevronRight from "../../icon/ChevronRight";
import CrossIcon from "../../icon/CrossIcon.jsx";
import RadioFilled from "../../icon/RadioFilled";
import { data } from "./data.js";

export default function PlanPricingTable({ styles }) {
    const router = useRouter();
    const pathname = usePathname();
    const pathArray = pathname?.split("/");

    const dispatch = useDispatch();
    const choosePlan = useSelector((state) => state.choosePlan);

    const [selectedButton, setSelectedButton] = useState("Stellar");
    const [selectedSubButton, setSelectedSubButton] = useState("1-Step");
    const [selectedPlan, setSelectedPlan] = useState(0);
    const [divBgColor, setDivBgColor] = useState(
        "linear-gradient(270deg, #3C1850 18.61%, rgba(60, 24, 80, 0.00) 100%)"
    );

    const buttonColors = {
        Stellar:
            "linear-gradient(270deg, #3C1850 18.61%, rgba(60, 24, 80, 0.00) 100%)",
        Evaluation:
            "linear-gradient(270deg, #2C2155 18.61%, rgba(44, 33, 85, 0.00) 100%)",
        Express:
            "linear-gradient(270deg, #142546 18.61%, rgba(20, 37, 70, 0.00) 100%)",

        StellarLite:
            "linear-gradient(270deg, #2D2F49 18.61%, rgba(45, 47, 73, 0.00) 100%)"
    };

    const handleButtonClick = (buttonKey, subButtonKey = "1-Step") => {
        setSelectedButton(buttonKey);
        setSelectedSubButton(subButtonKey);
        setSelectedPlan(0);
        setDivBgColor(buttonColors[buttonKey]);
    };

    const handlePlanSelection = (index) => {
        setSelectedPlan(index);
    };

    const buttonList = [
        {
            id: "Stellar",
            pathname: "stellar",
            button_name: "Stellar",
            subButtons: ["1-Step", "2-Step"]
        },
        { id: "Evaluation", pathname: "evaluation", button_name: "Evaluation" },
        {
            id: "Express",
            pathname: "express",
            button_name: "Express",
            subButtons: ["Consistency", "Non-Consistency"]
        },
        {
            id: "StellarLite",
            pathname: "stellar-lite",
            button_name: "Stellar Lite"
        }
    ];

    const currentPlans =
        selectedButton === "Stellar" || selectedButton === "Express"
            ? data[selectedButton][selectedSubButton].plans
            : data[selectedButton].plans;

    const currentFeatures =
        selectedButton === "Stellar" || selectedButton === "Express"
            ? data[selectedButton][selectedSubButton].features
            : data[selectedButton].features;

    useEffect(() => {
        const targetPath = choosePlan?.planName;
        const targetButton = buttonList.find(
            (btn) => btn.pathname === targetPath
        );
        dispatch(setPlanName(targetPath));
        if (
            ["stellar", "express", "evaluation", "stellar-lite"].includes(
                targetPath
            )
        ) {
            handleButtonClick(
                targetButton?.id,
                targetButton.subButtons ? targetButton.subButtons[0] : null
            );
        } else {
            router.push("/404");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [choosePlan?.planName]);

    const LOG_IN_URL = process.env.LOG_IN_URL || "https://app.fundednext.com";

    const handleGetPlans = (e) => {
        e.preventDefault();
        sentDataLayerEvent({
            event: "selected_plan",
            name: e.target.getAttribute("data-plan"),
            value: e.target.getAttribute("data-price")
        });
        window.location.href = e.target.getAttribute("href");
    };
    const handleRouteChange = (routeLink) => {
        dispatch(setPlanName(routeLink));
    };
    return (
        <div className={`container mx-auto px-4 pb-10 text-white ${styles}`}>
            <div className="mx-auto mb-6 flex max-w-[360px] items-center justify-between rounded-full border border-[#ffffff1a] bg-gradient-to-b from-[#ffffff0f] to-[#ffffff14] px-4 py-2">
                {buttonList.map((button) => (
                    <div key={button.id} className="relative">
                        <button
                            onClick={() => handleRouteChange(button?.pathname)}
                            className={`flex items-center justify-center border py-1 text-[13px] transition-all duration-200 ease-in-out ${
                                selectedButton === button.id
                                    ? "rounded-[30px] border-white border-opacity-10 bg-white/10 px-2.5 font-semibold"
                                    : "border-transparent px-1 font-medium opacity-50"
                            }`}
                        >
                            {button.button_name}
                        </button>
                    </div>
                ))}
            </div>

            {buttonList.find((button) => button.id === selectedButton)
                ?.subButtons && (
                <div className="mb-6 flex flex-wrap items-center justify-center gap-5">
                    <h3 className="text-[13px] font-medium text-[#ffffff80]">
                        Select Preference
                    </h3>
                    <div className="space-x-4">
                        {buttonList
                            .find((button) => button.id === selectedButton)
                            ?.subButtons.map((subButton) => (
                                <button
                                    key={subButton}
                                    onClick={() =>
                                        handleButtonClick(
                                            selectedButton,
                                            subButton
                                        )
                                    }
                                >
                                    <RadioFilled
                                        selected={selectedSubButton}
                                        item={subButton}
                                    />
                                    <span
                                        className={`text-xs font-medium ${
                                            selectedSubButton === subButton
                                                ? "text-white"
                                                : "text-[#ffffff80]"
                                        }`}
                                    >
                                        &nbsp; {subButton}
                                    </span>
                                </button>
                            ))}
                    </div>
                </div>
            )}

            <div className="relative rounded-2xl bg-[#ffffff0f]">
                <div
                    className="pointer-events-none absolute right-0 h-[72px] w-[100px] rounded-t-2xl"
                    style={{ background: divBgColor }}
                />
                <div className="hide-scroll-bar z-[999] overflow-x-scroll border-b border-[#ffffff0f]">
                    <div className="flex space-x-5 px-5 pt-6">
                        {currentPlans.map((plan, index) => (
                            <div
                                key={index}
                                className={`flex cursor-pointer flex-col items-center ${
                                    index === selectedPlan
                                        ? "text-white"
                                        : "text-[#453f3f80]"
                                } ${index === currentPlans.length - 1 ? "pr-12" : "pr-0"}`}
                                onClick={() => handlePlanSelection(index)}
                            >
                                <span
                                    className={`text-base  ${index === selectedPlan ? "font-bold text-white" : "font-medium text-[#ffffff99]"}`}
                                >
                                    {plan.amount}

                                    <div
                                        className={`${index === selectedPlan ? "mt-5 border border-b-2 border-white" : ""}`}
                                    ></div>
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="px-6">
                    <a
                        data-price={currentPlans[selectedPlan].fee.replace(
                            "$",
                            ""
                        )}
                        className="buyButton"
                        data-plan={
                            currentPlans[selectedPlan].name +
                            " " +
                            currentPlans[selectedPlan].amount
                        }
                        id={`buy${currentPlans[selectedPlan].planId}`}
                        href={`${LOG_IN_URL}/subscribe/${currentPlans[selectedPlan].planId}`}
                        onClick={handleGetPlans}
                    >
                        <span className="pointer-events-none mb-10 mt-6 flex h-[60px] w-full items-center justify-between rounded-[12px] bg-[#635BFF] px-5">
                            <div>
                                <p className="text-lg font-semibold">
                                    Get Plan
                                </p>
                                <p className="text-xs font-medium opacity-75">
                                    Fee: {currentPlans[selectedPlan].fee}
                                </p>
                            </div>
                            <ChevronRight />
                        </span>
                    </a>

                    <table className="w-full">
                        <tbody>
                            {currentFeatures.map((feature, featureIndex) => (
                                <tr key={featureIndex}>
                                    <td className="text-[13px] font-semibold text-white">
                                        {feature.name}
                                    </td>
                                    <td
                                        className={`border-l-[0.5px] border-[#ffffff1a] px-6 py-4 text-center text-xs font-semibold text-white ${
                                            featureIndex ===
                                            currentFeatures.length - 1
                                                ? "pb-6"
                                                : ""
                                        }`}
                                    >
                                        {feature.availability[selectedPlan] ===
                                        true ? (
                                            <CheckIcon className="h-[14px] w-[14px]" />
                                        ) : feature.availability[
                                              selectedPlan
                                          ] === false ? (
                                            <CrossIcon className="h-[14px] w-[14px] opacity-40" />
                                        ) : (
                                            // <>
                                            //     {
                                            //         feature.availability[
                                            //             selectedPlan
                                            //         ].split("|")[0]
                                            //     }
                                            //     <br />
                                            //     {
                                            //         feature.availability[
                                            //             selectedPlan
                                            //         ].split("|")[1]
                                            //     }
                                            //     </>
                                            <div className="text-[14px] font-normal">
                                                {typeof feature.availability[
                                                    selectedPlan
                                                ] === "string" ? (
                                                    feature.availability[
                                                        selectedPlan
                                                    ]
                                                ) : (
                                                    <>
                                                        {feature.availability[
                                                            selectedPlan
                                                        ].map((item, index) => (
                                                            <div
                                                                key={
                                                                    "feature-availabilty-" +
                                                                    index
                                                                }
                                                            >
                                                                {item}
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
