import React from "react";

export default function CheckIcon(props) {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
                {...props}
            >
                <path
                    d="M0.78125 5.63984L5.83438 10.693L15.2188 1.30859"
                    stroke="white"
                    strokeWidth="2"
                />
            </svg>
        </>
    );
}
